@import "normalize.css";

@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay/ClashDisplay-Regular.woff2') format('woff2'),
  url('../fonts/ClashDisplay/ClashDisplay-Regular.woff') format('woff'),
  url('../fonts/ClashDisplay/ClashDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay/ClashDisplay-Medium.woff2') format('woff2'),
  url('../fonts/ClashDisplay/ClashDisplay-Medium.woff') format('woff'),
  url('../fonts/ClashDisplay/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('../fonts/ClashDisplay/ClashDisplay-Semibold.woff2') format('woff2'),
  url('../fonts/ClashDisplay/ClashDisplay-Semibold.woff') format('woff'),
  url('../fonts/ClashDisplay/ClashDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'SimplyBites';
  src: url('../fonts/SimplyBites/SimplyBites-Regular.woff2') format('woff2'),
  url('../fonts/SimplyBites/SimplyBites-Regular.woff') format('woff'),
  url('../fonts/SimplyBites/SimplyBites-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


:root {
  font-size: 14px;
  --color-white: #FFFFFF;
  --color-orange: #EAB308;
  --color-yellow: #EED600;
  --color-black: #111111;
  --color-blue: #0587FF;
  --color-red: #EE0039;
  --color-purple: #5865F2;
  --color-gray: #8F8F8F;
  --color-green: #14F56E;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

* {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-sizing: border-box;
  font-family: 'ClashDisplay', sans-serif;
  text-decoration: none;
  &:not(body > *) {
    position: relative;
  }
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
  color: var(--color-white);
}

h1 {
  font-size: 4rem;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 3.5rem;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 2.5rem;
}

body {
  background: var(--color-black);
  color: var(--color-gray);
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }
}

header, section, footer {
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1772px;
  width: 100%;
  @media (max-width: 1820px) {
    padding: 0 1.5rem;
  }
}

span {
  font-weight: 500;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}